/* eslint comma-dangle: ["error", "always-multiline"] */
export default {
    actionCompleted: 'Действие выполнено',
    active: 'Активен',
    approved: 'Утвержден',
    businessDelete: 'Это действие навсегда удалит бизнес.\n Пожалуйста, введите следующее для подтверждения: "{name}"',
    businessDeleted: 'Бизнес успешно удален',
    cashFlowFinancial: 'Финансовая — финансовые операции для осуществления операционной или инвестиционной деятельности компании: привлечение и выплата кредитов, ввод/вывод денег в оборотный капитал собственниками и выплата дивидендов',
    cashFlowInvestment: 'Инвестиционная — покупка и продажа основных средств, вложение денег и проценты, которые получаем от этих вложений',
    cashFlowOperational: 'Операционная — все движения денег, связанные с основной деятельностью, которая дает прибыль',
    cashGap: 'Кассовый разрыв',
    chooseCategoryMoneyMovement: 'Выберите, к какой категории расходов относятся денежные операции по статье ДДС',
    chooseIncomeCategoryMoneyMovement: 'Выберите, к какой категории доходов относятся денежные операции по статье ДДС',
    chooseOutcomeCategoryMoneyMovement: 'Выберите, к какой категории расходов относятся денежные операции по статье ДДС',
    confirmAccessGroupDelete: 'Вы действительно хотите удалить группу доступа?',
    confirmAccountDelete: 'Вы действительно хотите удалить счет?',
    confirmAccountGroupDelete: 'Вы действительно хотите удалить группу счетов?',
    confirmAccountingItemDelete: 'Вы действительно хотите удалить учетную статью?',
    confirmActivityDelete: 'Вы действительно хотите удалить направление?',
    confirmAssessmentCategoryDelete: 'Вы действительно хотите удалить статью "{name}"?',
    confirmAssessmentDelete: 'Вы действительно хотите удалить начисление?',
    confirmBankCardDelete: 'Вы действительно хотите удалить банковскую карту?',
    confirmContractorDelete: 'Вы действительно хотите удалить контрагента?',
    confirmDealDelete: 'Вы действительно хотите удалить сделку "{name}"?',
    confirmDealStatusDelete: 'Вы действительно хотите удалить статус сделки?',
    confirmDepartmentDelete: 'Вы действительно хотите удалить отдел "{name}"?',
    confirmDocumentDisapprove: 'Вы действительно хотите отменить утверждение документа?',
    confirmEmployeeDelete: 'Вы действительно хотите удалить сотрудника "{name}"?',
    confirmEmploymentDelete: 'Вы действительно хотите удалить компанию "{name}"?',
    confirmFundingClaimStageDelete: 'Вы действительно хотите удалить этап согласования заявки?',
    confirmFundingClaimStageRuleDelete: 'Вы действительно хотите удалить правило этапа согласования заявки?',
    confirmIntegrationDelete: 'Вы действительно хотите удалить настройки интеграции?',
    confirmInvoiceDelete: 'Вы действительно хотите удалить счет на оплату?',
    confirmLeaderSet: 'Вы действительно хотите назначить руководителем сотрудника "{name}"?',
    confirmLoanDelete: 'Вы действительно хотите удалить кредит/займ?',
    confirmPaymentDelete: 'Вы действительно хотите удалить выплату?',
    confirmPositionDelete: 'Вы действительно хотите удалить должность "{name}"?',
    confirmProductDelete: 'Вы действительно хотите удалить услугу/товар?',
    confirmRecalculateAssessments: 'Начисления были изменены вручную. Вы действительно хотите пересчитать их?',
    confirmRecalculatePayments: 'Вы действительно хотите пересчитать выплаты?',
    confirmRequisiteDelete: 'Вы действительно хотите удалить реквизит?',
    confirmReturnInvoice: 'Вы уверены, что хотите вернуть счет в работу?',
    confirmRevokeInvoice: 'Вы уверены, что хотите аннулировать этот счет?',
    confirmStaffingChangeDelete: 'Вы действительно хотите удалить кадровое изменение?',
    confirmTransactionDelete: 'Вы действительно хотите удалить операцию?',
    confirmVacationPayDelete: 'Вы действительно хотите удалить начисление отпускных?',
    confirmWorkingModeDelete: 'Вы действительно хотите удалить режим работы "{name}"?',
    createDepartmentInOrgstructure: 'Создать отдел в организационной структуре',
    creatingBusiness: 'Создание бизнеса...',
    dadataDescription: 'Быстрый ввод адресов, компаний, банков.',
    deactivationBusinessActivityWarning: 'Внимание! Вы деактивировали направление, к которому привязан отдел организационной структуры {name}. Отдел орг. структуры нужно также деактивировать.',
    deactivationDepartmentWarning: 'Внимание! Вы деактивировали отдел, к которому привязано направление {name}. Направление нужно также деактивировать.',
    decryptionAdded: 'Добавлена расшифровка',
    decryptionAmount: 'Всего в расшифровке',
    decryptionAmountExceeded: 'Превышена сумма операции',
    decryptionDeleted: 'Удалена расшифровка',
    decryptionRemains: 'Осталось распределить',
    deleteRow: 'Удалить строку',
    dismissed: 'Уволен',
    dismissedFrom: 'Уволен с {number} числа',
    duplicateAssessmentRuleByDepartment: 'Нельзя назначить одинаковые начисления в рамках одного отдела',
    duplicateAssessmentRuleSalaryByDepartment: 'Нельзя назначить несколько окладов в рамках одного отдела',
    editRow: 'Редактировать строку',
    employeeNotFound: 'Сотрудник не найден',
    empty: 'пусто',
    emptyAssessmentRuleCategory: 'Не заполнено поле "Начисление"',
    emptyAssessmentRuleDepartment: 'Не заполнено поле "Отдел"',
    emptyAssessmentRuleSum: 'Не заполнено поле "Сумма"',
    emptyAssessmentRules: 'Не заполнен расчет ежемесячных начислений',
    emptyEffectiveDate: 'Вы не указали с какой даты изменения вступают в силу',
    emptyField: 'Поле не заполнено',
    enterText: 'Введите текст',
    fileAdded: 'Добавлен файл',
    fileDeleted: 'Удален файл',
    forceExchangeStarted: 'Принудительный запуск обмена запущен и будет завершен в течение нескольких минут',
    forceStart: 'Запустить принудительно',
    from: 'с',
    fundingClaimNumberAndDate: '№{number} от {date}',
    fundingClaimPurposeProfessionalIncomeTax: 'Оплата по счету №{number}\nСумма {total}\nНДФЛ и НДС не облагается, так как исполнитель применяет НПД',
    fundingClaimPurposeVAT: 'Оплата по счету №{number}\nСумма {total}\nВ т.ч. НДС ({rate}%) {VAT}',
    fundingClaimPurposeWithoutVAT: 'Оплата по счету №{number}\nСумма {total}\nБез налога (НДС)',
    hasOverduePayments: 'Имеются просроченные оплаты',
    inYear: 'В год',
    invalidEmail: 'Неверный формат адреса email',
    invalidFileType: 'Неверный тип файла',
    invalidPhone: 'Неверный телефон',
    invalidValue: 'Неверное значение',
    lengthMustBe: 'Длина должна быть',
    linkedTransactionsHaveChanges: 'В дополнительных операциях по оплате внесены изменения не позволяющие изменить реквизиты и/или сумму в текущей операции. Чтобы внести изменения, отвяжите заявку на оплату или сбросьте расшифровку на "Нераспределенные списания"/"Нераспределенные поступления" в операциях: {transactions}',
    maximumFileSizeExceeded: 'Превышен максимально допустимый размер файла',
    no: 'Нет',
    noData: 'Нет данных',
    notActive: 'Не активен',
    notApproved: 'Не утвержден',
    notFound: 'Ничего не найдено',
    notFoundAssessmentRuleDepartment: 'Нельзя назначить начисление к отделу, в котором сотрудник не работает',
    notRecruited: 'Не принят',
    or: 'или',
    rowWithNumber: 'Строка {number}',
    savedSuccess: 'Успешно сохранено',
    selectDepartments: 'Выберите отдел',
    sumInPaymentScheduleNotEqual: 'Сумма в графике платежей не равна сумме счета',
    sumTransaction: 'Не совпадает с суммой расшифровки',
    systemUser: 'Системный пользователь',
    timesheetHoursNotEqual: 'Количество часов не может быть больше количества часов по производственному календарю',
    timesheetMaxHours: 'Максимальное количество часов согласно рабочему табелю - {hours}',
    to: 'по',
    toDate: 'до {date}',
    today: 'сегодня',
    todayCapitalize: '@.capitalize:message.today',
    transactionLinkedWithAnotherTransaction: 'Операция связана с другой операцией',
    unknownAccountType: 'Неизвестный тип счета',
    valueMustBeGreaterThan: 'Значение должно быть больше {value}',
    valueMustBeLessThan: 'Значение должно быть меньше {value}',
    works: 'Принят с',
    worksFrom: 'Принят с {number} числа',
    yes: 'Да',
    confirmDataIsDirty: 'Вы уверены, что хотите закрыть страницу? Внесённые изменения не сохранятся.',
};
