import { createPayment, deletePayment, getPayment, getPayments, updatePayment } from '@/api/planning/fundingClaimPayment';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    doFetchList (params, loadMore) {
        return getPayments(params, loadMore);
    },
    doFetchSingle (id) {
        return getPayment(id);
    },
    doCreate (data, params) {
        return createPayment(data, params);
    },
    doUpdate (id, data, params) {
        return updatePayment(id, data, params);
    },
    doDelete (id) {
        return deletePayment(id);
    },
});
