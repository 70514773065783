export enum PersonnelDocumentTypes {
    RECRUITMENT = 'recruitment',
    CHANGE = 'change',
    DISMISSAL = 'dismissal',
    VACATION = 'vacation',
    SEPARATION_LEAVE = 'separation_leave',
    VACATION_COMPENSATION = 'vacation_compensation',
    DISMISSAL_VACATION_COMPENSATION = 'dismissal_vacation_compensation',
    SICK_LIST = 'sick_list',
    PARENTAL_LEAVE = 'parental_leave'
}

export enum ProbationUnits {
    DAY = 'day',
    WEEK = 'week',
    MONTH = 'month'
}

export enum EmploymentTypes {
    FULL_TIME_5 = 'full_time_5',
    SELF = 'self',
    FREELANCE = 'freelance',
    FREE = 'free'
}

export enum PaymentDocumentTypes {
    PAYROLL = 'payroll',
    RECOVERY = 'recovery',
    TAX = 'tax',
    PFR = 'pfr',
    FSS = 'fss',
    FFOMS = 'ffoms',
    PFR_FSS_FFOMS = 'pfr_fss_ffoms',
    FSS_FOR_ACCIDENTS = 'fss_for_accidents'
}

export enum PaymentTypes {
    ADVANCE = 'advance',
    SALARY = 'salary',
    VACATION = 'vacation',
    DISMISSAL = 'dismissal',
    GPC = 'gpc',
    SICK = 'sick',
    MATERNITY_LEAVE = 'maternity_leave',
    FEE = 'fee',
    UNUSUAL = 'unusual',
    BUSINESS_TRIP = 'business_trip',
}

export enum AssessmentCategoryPurposes {
    FIXED = 'fixed',
    PREMIUM = 'premium',
    OTHER = 'other'
}

export enum AssessmentCategoryCalculationTypes {
    FORMULA = 'formula',
    MANUAL = 'manual',
    FIXED = 'fixed'
}

export enum AssessmentCategoryDirections {
    IN = 'in',
    OUT = 'out'
}

export enum AccountTypes {
    BANK = 'bank',
    CASH = 'cash'
}

export enum TransactionCategoryTypes {
    INCOME = 'income',
    OUTCOME = 'outcome',
    ASSETS = 'assets',
    LIABILITIES = 'liabilities',
    CAPITAL = 'capital'
}

export enum TransactionTypes {
    INCOME = 'income',
    OUTCOME = 'outcome',
    INNER_INCOME = 'inner_income',
    INNER_OUTCOME = 'inner_outcome',
    TRANSFER = 'transfer'
}

export enum DealStageSemanticTypes {
    NEW = 'new',
    IN_WORK = 'in_work',
    SUCCESS = 'success',
    FAILURE = 'failure'
}

export enum AnalyticTypes {
    ACTIVITY = 'activity',
    EMPLOYEE = 'employee',
    CONTRACTOR_PROJECT = 'contractor_project',
    DEAL = 'deal',
    BUSINESS_GROUP_EMPLOYEE = 'business_group_employee',
    DIVIDEND_ALLOCATION = 'dividend_allocation',
    LOAN = 'loan',
    CONTRACTOR = 'contractor'
}

export enum AnalyticTypeProperties {
    ACTIVITY = 'analyticActivity',
    EMPLOYEE = 'analyticEmployee',
    DEAL = 'analyticDeal',
    BUSINESS_GROUP_EMPLOYEE = 'analyticBusinessGroupEmployee',
    DIVIDEND_ALLOCATION = 'analyticDividendAllocation',
    LOAN = 'analyticLoan',
    CONTRACTOR = 'analyticContractor'
}

export enum RequisiteTypes {
    LLC = 'llc',
    INDIVIDUAL = 'individual',
    IE = 'ie',
    GOVERNMENT = 'government',
    SELF_EMPLOYED = 'self_employed'
}

export enum ContractorCodes {
    UNALLOCATED = 'unallocated',
    EMPLOYEES = 'employees'
}

export enum CashFlowTypes {
    OPERATIONAL = 'operational',
    INVESTMENT = 'investment',
    FINANCIAL = 'financial'
}

export enum DayTypes {
    WORK_DAY = 'work_day',
    SHORT_WORK_DAY = 'short_work_day',
    HOLIDAY = 'holiday',
    DAY_OFF = 'day_off',
    SICK_LEAVE = 'sick_leave',
    VACATION = 'vacation',
    PARENTAL_LEAVE = 'parental_leave'
}

export enum TransactionItemDocumentTypes {
    PAYMENT_ITEM = 'payment_item',
    FUNDING_CLAIM_PAYMENT_ITEM = 'funding_claim_payment_item',
    INVOICE_PRODUCT = 'invoice_product',
    TRANSACTION_ITEM_REFUND = 'transaction_item_refund'
}

export enum PurchaseDocumentTypes {
    TRANSACTION_ITEM = 'transaction_item',
    FUNDING_CLAIM_PAYMENT_ITEM = 'funding_claim_payment_item'
}

export enum TransactionCategoryCodes {
    FOT = 'employees_phot',
    CLIENTS_REVENUE = 'clients_revenue',
    EMPLOYEE_LOAN_ISSUANCE = 'employee_loan_issuance',
    PHOT_TAX = 'phot_tax',
    INCOME_TAX = 'income_tax',
    BUSINESS_GROUP_LOAN_ISSUANCE = 'business_group_loan_issuance',
    BUSINESS_GROUP_LOAN_REPAYMENT = 'business_group_loan_repayment',
    EMPLOYEE_LOAN_REPAYMENT = 'employee_loan_repayment',
    BUSINESS_GROUP_LOAN_PERCENTS = 'business_group_loan_percents',
    BUSINESS_GROUP_LOAN_PAYMENT = 'business_group_loan_payment',
    BUSINESS_GROUP_GETTING_LOAN = 'business_group_getting_loan',
    UNALLOCATED_OUTCOME = 'unallocated_outcome',
    INNER_TRANSFER = 'inner_transfer',
    DIVIDENDS = 'dividends',
    INSURANCE_FEE = 'insurance_fee',
    NDFL = 'ndfl',
    PROVIDING_SERVICES_WITHIN_DEPARTMENTS = 'providing_services_within_departments',
    PROPERTY = 'property'
}

export enum LoanParentCodes {
    LOAN_PERCENTS = 'loan_percents',
    GETTING_LOAN = 'getting_loan',
    LOAN_ISSUANCE = 'loan_issuance',
    LOAN_REPAYMENT = 'loan_repayment',
    FINANCIAL_TRANSACTIONS_INCOME = 'financial_transactions_income',
    LOAN_PAYMENT = 'loan_payment'
}

export enum AnalyticChooseTypes {
    NOT_USE = 'not_use',
    AT_FILLING = 'at_filling',
    CERTAIN = 'certain'
}

export enum FieldTypes {
    STRING = 'string',
    LIST = 'list',
    DATE = 'date',
    NUMBER = 'number',
    CHECKBOX = 'checkbox',
    LINK = 'link',
    BUSINESS_REQUISITE = 'business_requisite',
    BUSINESS_ACCOUNT = 'business_account',
}

export enum FieldTypePlacement {
    CONDITIONS_TAB = 'conditions_tab',
    MAIN_DATA_BLOCK = 'main_data_block',
}

export enum ApprovingFormatTypes {
    BY_PAYMENT = 'by_payment',
    BY_PAYMENT_ITEM = 'by_payment_item'
}

export enum ContractTypes {
    EXPENSE = 'expense',
    INCOME = 'income',
    INTERNAL = 'internal',
}

export enum ContractAdditionalAgreementsTypes {
    MODIFICATION = 'modification',
    TERMINATION = 'termination',
}

export enum StageRuleConditionOperatorTypes {
    GT = 'gt',
    LT = 'lt',
    EQ = 'eq',
    NEQ = 'neq'
}

export enum StageRuleConditionParameterTypes {
    TRANSACTION_CATEGORY = 'transaction_category',
    SUM_AMOUNT = 'sum_amount',
    PAYER_REQUISITE = 'payer_requisite',
    PAYER_ACCOUNT = 'payer_account',
    ANALYTICS = 'analytics',
    PAYER_ACCOUNT_TYPE = 'payer_account_type',
    APPLICANT = 'applicant'
}

export enum StageRuleApproverTypes {
    OTHER_USER = 'other_user',
    BY_ORGCHART = 'by_orgchart',
    EMPLOYEE = 'employee',
    ACTIVITY_HEAD = 'activity_head',
    RESPONSIBLE_FOR_DEAL = 'responsible_for_deal',
    RESPONSIBLE_FOR_PROJECT = 'responsible_for_project',
    INITIATOR = 'initiator',
    NOBODY = 'nobody'
}

export enum PayerAccountTypes {
    BANK = 'bank',
    CASH = 'cash'
}

export enum EntityTypes {
    FUNDING_CLAIM = 'funding_claim',
    FUNDING_CLAIM_PAYMENT = 'funding_claim_payment',
    TRANSACTION = 'transaction',
    DEAL = 'deal',
    INVOICE = 'invoice',
    STAFF_PERSONNEL_DOCUMENT = 'staff_personnel_document',
    STAFF_ASSESSMENT_DOCUMENT = 'staff_assessment_document',
    RECEIPT_FIXED_ASSET_DOCUMENT = 'receipt_fixed_asset_document',
    FIXED_ASSET = 'fixed_asset',
    PRODUCT_INVENTORY = 'product_inventory',
    CONTRACT = 'contract',
    INTERNAL_DOCUMENT = 'internal_document',
}

export enum DepartmentTypes {
    COMMERCIAL = 'commercial',
    PRODUCTION = 'production',
    ADMINISTRATIVE = 'administrative'
}

export enum LoanTypes {
    EMPLOYEE = 'employee',
    CONTRACTOR = 'contractor'
}

export enum LoanDirectionTypes {
    IN = 'in',
    OUT = 'out'
}

export enum LoanStatusTypes {
    ACTIVE = 'active',
    COMPLETED = 'completed'
}

export enum AnalyticFieldTypes {
    ANALYTIC_ACTIVITY = 'analyticActivity',
    ANALYTIC_DEAL = 'analyticDeal',
    ANALYTIC_EMPLOYEE = 'analyticEmployee',
    ANALYTIC_BUSINESS_GROUP_EMPLOYEE = 'analyticBusinessGroupEmployee',
    ANALYTIC_DIVIDEND_ALLOCATION = 'analyticDividendAllocation',
    ANALYTIC_LOAN = 'analyticLoan',
    ANALYTIC_CONTRACTOR = 'analyticContractor',
    ANALYTIC_CONTRACTOR_PROJECT = 'analyticContractorProject'
}

export enum Genders {
    MALE = 'male',
    FEMALE = 'female'
}

export enum IntegrationEngineTypes {
    TELEGRAM = 'telegram',
    WEBHOOKS = 'webhooks',
    FINOLOG = 'finolog',
    ONEC_ACCOUNTING = 'onec_accounting',
    ONEC_ZUP = 'onec_zup',
    ZENMONEY = 'zenmoney',
    EMAIL = 'email',
    TOCHKA_BANK = 'tochka_bank',
    ALFA_BANK = 'alfa_bank',
    MODUL_BANK = 'modul_bank',
    TBANK = 'tbank'
}

export enum NotificationsTypes {
    EMAIL = 'email',
    SITE = 'site',
    TELEGRAM = 'telegram',
}

export enum NotificationsModesTypes {
    DISABLED = 'disabled',
    IMMEDIATELY = 'immediately',
    POSTPONED = 'postponed',
}

export enum InvoiceStageTypes {
    NOT_PAID = 'not_paid',
    PARTIALLY_PAID = 'partially_paid',
    PAID = 'paid',
    REVOKED = 'revoked'
}

export enum InvoiceThirdTypes {
    PAYEE = 'payee',
    PAYER = 'payer',
}

export enum MeasureTypes {
    UNITS = 'units',
    PERCENTS = 'percents'
}

export enum TimelineEntryTypes {
    CREATION = 'creation',
    MODIFICATION = 'modification',
    COMMENT = 'comment',
    FUNDING_CLAIM_PAYMENT_STAGE_CHANGED = 'funding_claim_payment_stage_changed',
    FUNDING_CLAIM_PAYMENT_TRANSITION = 'funding_claim_payment_transition',
    FUNDING_CLAIM_PAYMENT_TRANSACTION_BINDING = 'funding_claim_payment_transaction_binding',
    FUNDING_CLAIM_PAYMENT_TRANSACTION_UNBINDING = 'funding_claim_payment_transaction_unbinding',
    INVOICE_TRANSITION = 'invoice_transition',
    INVOICE_TRANSITION_BINDING = 'invoice_transaction_binding',
    INVOICE_TRANSITION_UNBINDING = 'invoice_transaction_unbinding',
    DEAL_TRANSITION = 'deal_transition',
    ANALYTIC_TO_DEAL_TRANSACTION_BINDING = 'analytic_to_deal_transaction_binding',
    ANALYTIC_TO_DEAL_TRANSACTION_UNBINDING = 'analytic_to_deal_transaction_unbinding',
    CONTRACT_STAGE_CHANGED = 'contract_stage_changed',
    CONTRACT_TRANSITION = 'contract_transition',
    CONTRACT_APPROVE_BY_PARTICIPANT = 'contract_approve_by_participant',
    CONTRACT_DEADLINE = 'contract_deadline'
}

export enum TimelineChangeTypes {
    FIELD_EDIT = 'field_edit',
    COLLECTION_RECORD_ADD = 'collection_record_add',
    COLLECTION_RECORD_DELETE = 'collection_record_delete',
    COLLECTION_RECORD_EDIT = 'collection_record_edit'
}

export enum BusinessMemberTypes {
    OWNER = 'owner',
    USER = 'user'
}

export enum FundingClaimPaymentSemanticTypes {
    NEW = 'new',
    PROCESS = 'process',
    SUCCESS = 'success',
    FAILURE = 'failure'
}

export enum FundingClaimPaymentCodeTypes {
    NEW = 'new',
    APPROVING = 'approving',
    PAYMENT_APPROVING = 'payment_approving',
    PAYMENT_WAITING = 'payment_waiting',
    UNLOADED_TO_BANK = 'unloaded_to_bank',
    PARTIALLY_PAID = 'partially_paid',
    DOCUMENTS_CHECKING = 'documents_checking',
    PAID = 'paid',
    REJECTED = 'rejected'
}

export enum VATTypes {
    NO_VAT = 'no_vat',
    SELF_EMPLOYED_TAX = 'self_employed_tax',
    VAT_20 = 'vat_20'
}

export enum BusinessMemberRoleTypes {
    OWNER = 'owner',
    USER = 'user',
    INACTIVE = 'inactive'
}

export enum IntervalGroupTypes {
    QUARTER = 'quarter',
    MONTH = 'month',
    WEEK = 'week',
    DAY = 'day'
}

export enum PurchaseStatus {
    ORIGINAL_RECEIVED = 'original_received',
    SCAN_RECEIVED = 'scan_received',
    RECEIVED_VIA_EDO = 'received_via_edo',
    NOT_SIGNED = 'not_signed',
    REVOKED = 'revoked'
}

export enum WaitingForPurchaseTypes {
    WAITING_FOR_SHIPMENT = 'waiting_for_shipment',
    SHIPMENT_WAS_SHIPPED = 'shipment_was_shipped'
}

export enum FundingClaimPaymentTypes {
    SIMPLE = 'single',
    MULTIPLE = 'multi'
}

export enum EmployeeDocumentTypes {
    RECRUITMENT = 'recruitment',
    DISMISSAL = 'dismissal',
    SEPARATION_LEAVE = 'separation_leave',
    VACATION = 'vacation'
}

export enum AccountingPeriodTypes {
    NONE = 'none',
    BY_ACCOUNTING_PERIOD = 'by_accounting_period',
    BY_DEAL = 'by_deal',
    BY_DOCUMENT = 'by_document',
    BY_PAYMENT_DATE = 'by_payment_date',
    ADVANCE = 'advance',
}


export enum ActivationDateTypes {
    BY_DATE = 'by_date',
    FROM_AMENDMENT_DATE = 'from_amendment_date',
}

export enum TransactionCategoryTypeGroupTypes {
    INCOME_LESS_THAN_EBITDA = 'income_less_than_ebitda',
    OPERATING_INCOME = 'operating_income',
    PRODUCTION = 'production',
    PRODUCTION_VARIABLES = 'production_variables',
    PERMANENT_PRODUCTION = 'permanent_production',
    GENERAL_PRODUCTION_EXPENSES = 'general_production_expenses',
    COMMERCIAL = 'commercial',
    ADMINISTRATIVE = 'administrative',
    OUTCOME_LESS_THAN_EBITDA = 'outcome_less_than_ebitda',
    COSTS_BELOW_NET_INCOME = 'costs_below_net_income',
    NOT_CONSIDERED = 'not_considered'
}

export enum DealStageCodes {
    COMPLETED = 'completed',
    CANCELED = 'canceled'
}

export enum OpiuIncomeGroup {
    CUSTOMERS_REVENUE = 'customers_revenue',
    INCOMES_LESS_EBITDA = 'incomes_less_ebitda',
    UNALLOCATED_INCOME = 'unallocated_income',
    INCOME_REFUND = 'income_refund'
}

export enum OpiuTotalGroup {
    MARGINAL_PROFIT = 'marginal_profit',
    GROSS_PROFIT = 'gross_profit',
    EBITDA = 'ebitda',
    NET_INCOME = 'net_income',
    UNALLOCATED = 'unallocated'
}

export enum OpiuExpenseGroup {
    PRODUCTION_VARIABLE = 'production_variables',
    PERMANENT_PRODUCTION = 'permanent_production',
    COMMERCIAL = 'commercial',
    ADMINISTRATIVE = 'administrative',
    EXPENSES_LESS_EBITDA = 'expenses_less_ebitda',
    EXPENSES_LESS_NET_INCOME = 'expenses_less_net_income',
    UNALLOCATED_EXPENSE = 'unallocated_expense',
    DIRECT_DEAL_PRODUCTION_VARIABLES = 'direct_deal_production_variables',
    INCOME_TAX = 'income_tax',
    GENERAL_PRODUCTION = 'general_production',
    OUTCOME_REFUND = 'outcome_refund',
    AMORTIZATION = 'amortization'
}

export enum BdrExpenseGroup {
    PHOT_COMMERCIAL_DIVISION = 'phot_commercial_division',
    PHOT_ADMINISTRATION_DIVISION = 'phot_administration_division',
    PHOT_PRODUCTION = 'phot_production'
}

export enum PropertyMovementTypes {
    ADMISSION = 'admission',
    MOVING = 'moving'
}

export enum AssessmentsMode {
    WITH_TAX = 'with_tax',
    WITHOUT_TAX = 'without_tax'
}
export enum DistributionProductTypes {
    AT_COST = 'at_cost',
    IN_THE_NUMBER_OF = 'in_the_number_of',
    IS_NOT_DISTRIBUTED = 'is_not_distributed'
}

export enum PossessionsTypes {
    MOVEMENT_PRODUCT = 'movement_product',
    MOVEMENT_FIXED_ASSETS = 'movement_fixed_assets',
    RECEIPT_PRODUCT = 'receipt_product',
    RECEIPT_FIXED_ASSETS = 'receipt_fixed_assets',
    // UNALLOCATED_EXPENSE = 'unallocated_expense'
}

export enum ZupSalaryDocumentTypes {
    BANK_PAY_SLIP = 'bank_pay_slip',
    CASH_PAY_SLIP = 'cash_pay_slip',
    SALARY_ASSESSMENT = 'salary_assessment',
    TRANSFER_PAY_SLIP = 'transfer_pay_slip',
    PREMIUM  = 'premium',
    ASSESSMENT_FOR_FIRST_HALF_OF_MONTH  = 'assessment_for_first_half_of_month'
}

export enum ZupContractDocumentTypes {
    AUTHOR_ORDER_CONTRACT = 'author_order_contract',
    COMPLETED_WORK_CERTIFICATE = 'completed_work_certificate',
    SERVICE_CONTRACT = 'service_contract',
    PERFORMANCE_LIST = 'performance_list',
    WITHHOLD_TO_ACCOUNT_FOR_OTHER_TRANSACTIONS = 'withhold_to_account_for_other_transactions'
}

export enum ZupLoanDocumentTypes {
    LOAN = 'loan'
}

export enum ZupDocumentTypes {
    ABSENCE = 'absence',
    BUSINESS_TRIP = 'business_trip',
    CHANGE = 'change',
    COMPENSATORY_HOLIDAY = 'compensatory_holiday',
    DISMISSAL = 'dismissal',
    RECRUITMENT = 'recruitment',
    SICK_LIST = 'sick_list',
    VACATION = 'vacation',
    PARENTAL_LEAVE = 'parental_leave',
    RETURN_FROM_PARENTAL_LEAVE = 'return_from_parental_leave',
    VACATION_SCHEDULE = 'vacation_schedule',
    VACATION_TRANSFER = 'vacation_transfer',
    VACATION_WITHOUT_PAY = 'vacation_without_pay',
    CHANGE_LIST = 'change_list',
    RECRUITMENT_LIST = 'recruitment_list'
}

export enum MarkTypes {
    EXCLAMATION = 'exclamation',
    QUESTION = 'question'
}

export enum TransactionDeleteRights {
    ONLY_THEIR = 'can_delete_only_their',
    CAN_ALL = 'can_all'
}

export enum TransactionUpdateRights {
    ONLY_ITEMS = 'can_update_only_items',
    CAN_ALL = 'can_all',
    ONLY_REQUISITE = 'can_update_only_requisite',
}

export enum AssessmentDocumentReadRights {
    ALL = 'can_all',
    ONLY_THEIR = 'can_only_their',
    THEIR_DEPARTMENT = 'can_their_department',
    THEIR_DEPARTMENT_WITH_CHILDREN = 'can_their_department_with_children'
}

export enum ContractRights {
    CAN_ALL = 'can_all',
    CONTRACT_TYPE = 'contract_type'
}

export enum ContractRuleGenerationType {
    NUMBERS = 'numbers',
    PREFIX_AND_NUMBERS = 'prefix_and_numbers',
    DISABLE = 'disable'
}

export enum ContractRuleNumberGenerationMode {
    FIRST_FREE = 'first_free',
    LAST_NUMBER_INCREASED = 'last_number_increased'
}

export enum AssessmentDocumentItems {
    PERSONAL_INCOME_TAX = 'personal_income_tax',
    DEPOSITS = 'deposits',
    PFR = 'pfr',
    FSS = 'fss',
    FSS_FOR_ACCIDENT = 'fss_for_accidents',
    FFOMS = 'ffoms'
}

export enum AssessmentDocumentUpdateRights {
    ALL = 'can_all',
    ONLY_THEIR = 'can_only_their',
    THEIR_DEPARTMENT = 'can_their_department',
    THEIR_DEPARTMENT_WITH_CHILDREN = 'can_their_department_with_children'
}

export enum PaymentDocumentExtendedRights {
    CAN_ALL = 'can_all',
    ONLY_1C_ZUP = 'only_1c_zup',
    ONLY_PAYMENTS_CREATED_BY_HAND = 'only_payments_created_by_hand'
}

export enum LoanAnalyticCodes {
    BANK_GETTING_LOAN = 'bank_getting_loan',
    GETTING_LOAN_FROM_OTHER_COMPANIES = 'getting_loan_from_other_companies',
    BUSINESS_GROUP_LOAN_IN = 'business_group_loan_in',
    BUSINESS_GROUP_LOAN_OUT = 'business_group_loan_out',
    LOAN_ISSUANCE_TO_OTHER_COMPANIES = 'loan_issuance_to_other_companies',
    EMPLOYEE_LOAN = 'employee_loan'
}

export const AccountingCategoryCodeDirections = {
    [LoanParentCodes.GETTING_LOAN]: 'in',
    [LoanParentCodes.FINANCIAL_TRANSACTIONS_INCOME]: 'out',
    [LoanParentCodes.LOAN_REPAYMENT]: 'out',
    [LoanParentCodes.LOAN_PERCENTS]: 'in',
    [LoanParentCodes.LOAN_PAYMENT]: 'in',
    [LoanParentCodes.LOAN_ISSUANCE]: 'out',
};

export enum AssessmentCategories {
    HOURLY_SALARY = 'hourly_salary',
    HOURLY_ABSENCE = 'hourly_absence',
    HOURLY_DECREASE = 'hourly_decrease',
    HOURLY_INCREASE = 'hourly_increase',
    VACATION = 'vacation',
    VACATION_COMPENSATION = 'vacation_compensation',
    DISMISSAL_VACATION_COMPENSATION = 'dismissal_vacation_compensation',
    SUPPLEMENT = 'supplement',
    PERSONAL_INCOME_TAX = 'personal_income_tax',
}

export enum AssessmentTaxTypes {
    DEPOSITS = 'deposits',
    PERSONAL_INCOME_TAX = 'personal_income_tax'
}

export enum TransactionImportStatuses {
    NEW = 'new',
    IN_PROCESS = 'in_process',
    PROCESSED = 'processed'
}

export enum TransactionImportTypeErrors {
    DUPLICATE = 'duplicate',
    CRITICAL = 'critical',
    NOT_CRITICAL = 'not_critical'
}

export enum FixedAssetStatuses {
    NOT_COMMISSIONED = 'not_commissioned',
    IN_OPERATION = 'in_operation',
    DEPRECATED = 'deprecated',
    CANCELLATION = 'cancellation'
}

export enum MIMETypes {
    XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    XLS = 'application/vnd.ms-excel',
    DOC = 'application/msword',
    DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    PDF = 'application/pdf',
    ZIP = 'application/zip'
}

export enum TransactionImportFields {
    DATE = 'date',
    DESCRIPTION = 'description',
    NUMBER = 'number',
    CATEGORY_NAME = 'categoryName',
    ACCOUNTING_PERIOD = 'accountingPeriod',
    CONTRACTOR_REQUISITE_NAME = 'contractorRequisiteName',
    CONTRACTOR_REQUISITE_INN = 'contractorRequisiteInn',
    CONTRACTOR_REQUISITE_KPP = 'contractorRequisiteKpp',
    CONTRACTOR_REQUISITE_ACCOUNT = 'contractorRequisiteAccount',
    CONTRACTOR_REQUISITE_BIC = 'contractorRequisiteBic',
    CONTRACTOR_REQUISITE_BANK_NAME = 'contractorRequisiteBankName',
    CONTRACTOR_REQUISITE_BANK_ACCOUNT = 'contractorRequisiteBankAccount',
    ANALYTIC_DEAL_NAME = 'analyticDealName',
    ANALYTIC_LOAN_NAME = 'analyticLoanName',
    ANALYTIC_ACTIVITY_NAME = 'analyticActivityName',
    ANALYTIC_CONTRACTOR_NAME = 'analyticContractorName',
    ANALYTIC_EMPLOYEE_FULL_NAME = 'analyticEmployeeFullName',
    ANALYTIC_DIVIDEND_ALLOCATION_OWNER = 'analyticDividendAllocationOwner',
    ANALYTIC_BUSINESS_GROUP_EMPLOYEE_FULL_NAME = 'analyticBusinessGroupEmployeeFullName',
    AMOUNT = 'amount',
    POSITIVE_AMOUNT = 'positiveAmount',
    NEGATIVE_AMOUNT = 'negativeAmount',
    BUSINESS_REQUISITE_INN = 'businessRequisiteInn',
    BUSINESS_REQUISITE_KPP = 'businessRequisiteKpp',
    BUSINESS_ACCOUNT = 'businessAccount',
    BUSINESS_ACCOUNT_BANK_BIC = 'businessAccountBankBic',
    BUSINESS_REQUISITE_NAME = 'businessRequisiteName',
}

export enum IntegrationAccountTypes {
    ZEN_MONEY = 'zen_money',
    EMAIL = 'email',
    TOCHKA_BANK = 'tochka_bank',
    ALFA_BANK = 'alfa_bank',
    MODUL_BANK = 'modul_bank',
    TBANK = 'tbank'
}

export enum ContractStageCodes {
    DRAFT = 'draft',
    FORMATION = 'formation',
    ARCHIVE_REGISTRATION = 'archive_registration',
    APPROVING = 'approving',
    WAITING_ORIGINAL = 'waiting_original',
    ORIGINAL_RECEIVED = 'original_received',
    DECLINED = 'declined',
    CONTENT_APPROVAL = 'content_approval',
    COMPLETED = 'completed'
}

export enum ContractVersionSectionCodes {
    SIGNED = 'signed',
    SIGNATURE = 'signature',
    ADVANCE = 'advance',
    ADVANCE_AND_SIGNATURE = 'advance_and_signature',
    SIGNATURE_AND_SIGNED = 'signature_and_signed',
}

export enum ContractStageRuleConditionParameters {
    CONTRACT_TYPE = 'contract_type',
    CONTRACT_VERSION = 'contract_version',
}

export enum ContractStageSemantics {
    NEW = 'new',
    PROCESS = 'process',
    SYSTEM = 'system',
    END = 'end',
}

export enum ContractTypeCodes {
    INTERNAL_EXPENSE = 'internal_expense',
    INTERNAL_INCOME = 'internal_income',
}

export enum TransactionVerificationStatuses {
    NEW = 'new',
    IN_PROCESS = 'in_process',
    PROCESSED = 'processed',
    ERROR = 'error',
}

export enum TransactionVerificationDiffTypes {
    NOT_FOUND_IN_FINIK = 'not_found_in_finik',
    NOT_FOUND_IN_BANK = 'not_found_in_bank',
    NOT_MATCH = 'not_match',
    NOT_REVISE = 'not_revise',
}

export enum NotificationGroups {
    SYSTEM = 'system'
}

export enum ContractSumTypes {
    NOT_RECORDED = 'not_recorded',
    FIXED_SUM = 'fixed_sum',
    REGULAR_PAYMENTS = 'regular_payments',
    FRAME_CONTRACT_REQUESTS = 'frame_contract_requests'
}
