import type { PluginFunction } from 'vue';
import Vue from 'vue';
import store from '@/store';

interface CanFunction {
    (attributes: string | string[], businessId?: string): boolean;
}

declare module 'vue/types/vue' {
    interface Vue {
        readonly $can: CanFunction;
    }
}

export default ((app: typeof Vue): void => {
    if (app.prototype.$can) {
        return;
    }

    Object.defineProperties(app.prototype, {
        $can: {
            get (): CanFunction {
                return (attributes: string | string[], businessId?: string): boolean => {
                    if (typeof attributes === 'string') {
                        attributes = [attributes];
                    }
                    let isDenied = attributes.length > 0;
                    businessId = businessId ?? store.getters['business/currentId'];

                    attributes.forEach(attribute => {
                        if (businessId) {
                            if (store.getters['security/hasAccess'](attribute, businessId)) {
                                isDenied = false;
                            }
                        } else {
                            for (const key of Object.keys(store.getters['security/permissions']())) {
                                if (store.getters['security/hasAccess'](attribute, key)) {
                                    isDenied = false;
                                    break;
                                }
                            }
                        }
                    });

                    return !isDenied;
                };
            },
        },
    });
}) as PluginFunction<any>;
