import axios from 'axios';
import { parse, stringify } from 'qs';
import * as Cookies from 'js-cookie';
import store from '@/store';
import router from '@/router';

const service = axios.create({
    baseURL: import.meta.env.VITE_API_URL,
    timeout: 30000,
    paramsSerializer: {
        encode: parse,
        serialize: (params) => stringify(params, { arrayFormat: 'brackets' }),
    },
});

const requestsMap = new Map();

service.interceptors.request.use(
    config => {
        if (store.getters['auth/token']) {
            config.headers.Authorization = `Bearer ${store.getters['auth/token']}`;
        }
        if (Cookies.get('XDEBUG_SESSION')) {
            config.params = config.params || {};
            config.params.XDEBUG_SESSION_START = Cookies.get('XDEBUG_SESSION');
        }
        if (Cookies.get('XDEBUG_PROFILE')) {
            config.params = config.params || {};
            config.params.XDEBUG_PROFILE = Cookies.get('XDEBUG_PROFILE');
        }
        // Api Platform не позволяет передавать UUID при создании
        if (config.method.toLowerCase() === 'post' && config.data && config.data.id) {
            config.headers['Content-Type'] = 'application/ld+json';
        }

        const controller = new AbortController();
        config.signal = controller.signal;
        requestsMap.set(config, controller);

        return config;
    },
    error => {
        console.error(error);
        return Promise.reject(error);
    },
);

let refreshAttempt = false;
service.interceptors.response.use(
    ({ data, config }) => {
        requestsMap.delete(config);
        return data;
    },
    async (error) => {
        requestsMap.delete(error.config);
        const route = router.currentRoute;
        const isUnauthorized = error.response?.status === 401;

        if (isUnauthorized && error.response?.data?.message === 'JWT Refresh Token Not Found') {
            return Promise.reject(error);
        }

        if (isUnauthorized) {
            // Если еще не выполнялась попытка обновить токен - пробуем получить новый
            if (
                !refreshAttempt &&
                !(error.config?.url ?? '').startsWith('/auth')
            ) {
                refreshAttempt = true;
                if (await store.dispatch('auth/refreshToken')) {
                    return service.request(error.config);
                }
            } else {
                refreshAttempt = false;
                await store.dispatch('auth/invalidateToken');
            }

            if (route.name !== 'Login') {
                await router.push({
                    name: 'Login',
                    query: { redirect: window.location.pathname + window.location.search },
                });
            }
        }

        return Promise.reject(error);
    },
);

export { requestsMap };
export default service;
