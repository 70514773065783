import * as AccessRoleAPI from '@/api/accessRole';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    doFetchList (params) {
        return AccessRoleAPI.getAccessRoles(params);
    },
    doFetchSingle (id) {
        return AccessRoleAPI.getAccessRole(id);
    },
    doCreate (data) {
        return AccessRoleAPI.createAccessRole(data);
    },
    doDelete (id) {
        return AccessRoleAPI.deleteAccessRole(id);
    },
    doUpdate (id, data) {
        return AccessRoleAPI.updateAccessRole(id, data);
    },
});
