import request from '@/utils/request';
import { Business, Member, MemberSettings } from '@/types/Business';
import { PagedCollection } from '@/types/hydra';
import type { AxiosRequestConfig } from 'axios';

const baseUri = '/businesses';

export default {
    find (id: string): Promise<Business> {
        return request.get(`${baseUri}/${id}`);
    },

    findBySlug (slug: string, params: object): Promise<Business> {
        return request.get(`/businesses_by_slug/${slug}`, { params });
    },

    findAll (params: object): Promise<PagedCollection<Business>> {
        return request.get(baseUri, { params });
    },

    create (payload = {}): Promise<Business> {
        return request.post(baseUri, payload);
    },

    update (id: string, payload: object): Promise<Business> {
        return request.patch(`${baseUri}/${id}`, payload);
    },

    delete (id: string): Promise<void> {
        return request.delete(`${baseUri}/${id}`);
    },

    updateMonthCloseDates (id: string, data: object): Promise<Business> {
        return request.patch(`${baseUri}/${id}/month_close_dates`, data);
    },

    changeAssessmentsMode (id: string, data: object) {
        return request.patch(`${baseUri}/${id}/assessments_mode`, data);
    },
};

export interface GetMembersRequest {
    businessGroupId: string;
    search?: string;
    withNotConfirmed?: boolean;
    ids?: string[];
    page?: number;
    pageSize?: number;
}

export const getMembers = ({ businessId, search, ids, page, pageSize, withNotConfirmed }: GetMembersRequest): Promise<PagedCollection<Member>> =>
    request({
        url: `${baseUri}/${businessId}/members`,
        method: 'GET',
        params: {
            search,
            ids,
            page,
            pageSize,
            withNotConfirmed
        },
    } as AxiosRequestConfig);

export interface AddMemberRequest {
    businessId: string;
    userId: string;
    roles: string[];
}

export const addMember = ({ businessId, userId, roles }: AddMemberRequest): Promise<null> =>
    request({
        url: `${baseUri}/${businessId}/members/${userId}`,
        method: 'PUT',
        data: { roles },
    } as AxiosRequestConfig);

export interface DeleteMemberRequest {
    businessId: string;
    userId: string;
}

export const deleteMember = ({ businessId, userId }: DeleteMemberRequest): Promise<null> =>
    request({
        url: `${baseUri}/${businessId}/members/${userId}`,
        method: 'DELETE',
    } as AxiosRequestConfig);

export const getNotificationSettings = (id: string, params: object): Promise<PagedCollection<MemberSettings>> =>
    request({
        url: `${baseUri}/${id}/members/notification_settings`,
        method: 'GET',
        params
    });
