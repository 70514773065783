<template>
    <el-popover
        :placement="placement"
        :disabled="disabled"
        @show="handleShow"
    >
        <template #reference>
            <slot />
        </template>

        <div class="">
            <el-input
                v-if="enableSearch"
                v-model="queryString"
                @input="handleInput"
            />


            <ui-select-cascader-popover
                v-for="item in currentItems"
                :key="item.id"
                :items="item.children"
                enable-search
                placement="right"
                :node="item"
                :fetch-items="fetchItems"
                :disabled="!item?.enableChildren"
            >
                <div @click="handleItemClick(item)">
                    {{ item.name }}
                </div>
            </ui-select-cascader-popover>
        </div>
    </el-popover>
</template>

<script setup lang="ts">
import { ref } from 'vue';

interface Item {
    id: string,
    name: string,
    enableChildren: boolean,
    multipleChildren: boolean,
    children?: Item[]
}

interface Props {
    node?: Item | null,
    fetchItems: (query: string, parent: any, cb: (items: Item[]) => void) => void,
    enableSearch?: boolean,
    placement?: string,
    disabled?: boolean,
    value: string | string[]
}

const props = withDefaults(defineProps<Props>(), {
    enableSearch: false,
    placement: 'bottom',
    node: null,
    disabled: false
});

const loading = ref(false);
const queryString = ref('');
const currentItems = ref<Item[]>([]);
// const currentValue = computed({
//     get () {
//         return props.value;
//     },
//     set (val) {
//
//     }
// });

function remoteMethod (query: string) {
    loading.value = true;
    queryString.value = query;
    props.fetchItems(query, props.node, items => {
        currentItems.value = items ?? [];
        loading.value = false;
    });
}

function handleShow () {
    remoteMethod('');
}

function handleInput (value: string) {
    remoteMethod(value);
}

function handleItemClick (item: Item) {
    if (!item?.enableChildren) {
        console.log('123123');
    }
}
</script>

<style scoped>

</style>
