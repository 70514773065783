import Pusher from 'pusher-js/pusher-with-encryption';
import store from '@/store';
import { default as PusherType } from 'pusher-js';

declare module 'vue/types/vue' {
    interface Vue {
        $pusher: PusherType;
    }
}

function createPusher () {
    const env = import.meta.env;

    const pusher: PusherType = new Pusher(env.VITE_PUSHER_KEY, {
        cluster: env.VITE_PUSHER_CLUSTER,
        wsHost: env.VITE_PUSHER_HOST,
        wssPort: env.VITE_PUSHER_PORT,
        forceTLS: true,
        encrypted: true,
        disableStats: true,
        enabledTransports: ['ws'],
        channelAuthorization: {
            endpoint: `${env.VITE_API_URL}/pusher/auth`,
            headersProvider: () => ({
                Authorization: `Bearer ${store.getters['auth/token']}`,
            }),
        },
    });

    return {
        install(app) {
            if (app.prototype.$pusher) {
                return;
            }

            Object.defineProperties(app.prototype, {
                $pusher: {
                    get () {
                        return pusher;
                    }
                }
            });
        }
    };
}

export default createPusher();
