<template>
    <div class="interval-selector">
        <el-button
            class="interval-selector__prev"
            :disabled="disabled || prevDisabled"
            @click="handlePrevButtonClick"
        >
            <iconify-icon icon="akar-icons:chevron-left" />
        </el-button>

        <template v-if="presets.length === 1 && ['week', 'month'].includes(currentPreset)">
            <el-button
                :disabled="disabled"
                :class="currentPreset"
                class="interval-selector__value"
                style="margin-left: 0"
                @click="$refs.singlePresetDatePicker.focus()"
            >
                {{ formattedValue }}

                <ui-date-picker
                    ref="singlePresetDatePicker"
                    :value="currentValue[0]"
                    type="month"
                    @input="handleSinglePresetDatePickerInput"
                />
            </el-button>
        </template>

        <el-popover
            v-else
            ref="popover"
            placement="bottom-start"
            popper-class="interval-selector__popover"
            trigger="click"
            :disabled="disabled || (presets.length <= 1 && presets[0] !== 'custom')"
        >
            <el-button
                slot="reference"
                :class="currentPreset"
                class="interval-selector__value"
            >
                {{ formattedValue }}
            </el-button>

            <div
                v-if="presets.length > 1"
                class="interval-selector__preset-list"
            >
                <el-button
                    v-for="preset in visiblePresets"
                    :key="preset"
                    :type="preset === currentPreset ? 'primary' : 'default'"
                    @click="applyPreset(preset)"
                >
                    {{ $t(`label.${preset}`) }}
                </el-button>
            </div>
            <ui-date-picker
                v-if="presets.includes('custom')"
                ref="datePicker"
                v-model="currentValue"
                class="interval-selector__date-picker"
                :class="{ 'is-active': currentPreset === 'custom' }"
                :clearable="false"
                :picker-options="pickerOptions"
                :type="pickerType"
                @change="handleDatePickerChange"
            />
        </el-popover>
        <el-button
            class="interval-selector__next"
            :disabled="disabled || nextDisabled"
            @click="handleNextButtonClick"
        >
            <iconify-icon icon="akar-icons:chevron-right" />
        </el-button>
    </div>
</template>

<script>
export default {
    name: 'UiIntervalSelector',

    props: {
        value: {
            type: Array,
            required: true,
            validator (value) {
                return value.length === 2;
            },
        },

        presets: {
            type: Array,
            required: false,
            default: () => ['week', 'month', 'quarter', 'year', 'custom'],
        },

        minDate: {
            type: Date,
            default: null,
        },

        maxDate: {
            type: Date,
            default: null,
        },

        pickerType: {
            type: String,
            default: 'daterange',
            validator: v => ['daterange', 'monthrange'].includes(v),
        },

        disabled: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        currentValue: {
            get () {
                return this.value;
            },

            set (value) {
                if (!Array.isArray(value) || value.length < 2) {
                    return;
                }

                if (value[0] && this.minDate && this.$dayjs(this.minDate).format('YYYY-MM-DD') > value[0]) {
                    value[0] = this.$dayjs(this.minDate).format('YYYY-MM-DD');
                }
                if (value[1] && this.maxDate && this.$dayjs(this.maxDate).format('YYYY-MM-DD') < value[1]) {
                    value[1] = this.$dayjs(this.maxDate).format('YYYY-MM-DD');
                }
                if (value[0] > value[1]) {
                    const tmp = value[0];
                    value[0] = value[1];
                    value[1] = tmp;
                }

                this.$emit('input', value);
            },
        },

        isFullMonthRange () {
            const startDate = this.$dayjs(this.currentValue[0]);
            const endDate = this.$dayjs(this.currentValue[1]);

            return startDate.format('YYYY-MM-DD') === startDate.startOf('month').format('YYYY-MM-DD') &&
                endDate.format('YYYY-MM-DD') === endDate.endOf('month').format('YYYY-MM-DD');
        },

        formattedValue () {
            const startDate = this.$dayjs(this.currentValue[0]);
            const endDate = this.$dayjs(this.currentValue[1]);
            const isSameYear = startDate.year() === endDate.year();
            const isSameMonth = isSameYear && startDate.month() === endDate.month();

            // Если даты совпадают
            if (startDate.format('YYYY-MM-DD') === endDate.format('YYYY-MM-DD')) {
                return startDate.format('D MMMM YYYY');
            }

            // Диапазон по полным годам
            if (
                startDate.format('YYYY-MM-DD') === startDate.startOf('year').format('YYYY-MM-DD') &&
                endDate.format('YYYY-MM-DD') === endDate.endOf('year').format('YYYY-MM-DD')
            ) {
                if (isSameYear) {
                    return startDate.year() + ' год';
                } else {
                    return startDate.year() + ' - ' + endDate.year();
                }
            }

            // Диапазон по полным месяцам
            if (this.isFullMonthRange) {
                if (isSameMonth) {
                    const start = startDate.format('MMMM YYYY');
                    return start[0].toUpperCase() + start.substring(1);
                } else {
                    const start = startDate.format(`MMMM${!isSameYear ? ' YYYY' : ''}`);
                    const end = endDate.format('MMMM YYYY');
                    return start[0].toUpperCase() + start.substring(1) + ' - ' + end[0].toUpperCase() + end.substring(1);
                }
            }

            return startDate.format(`D MMMM${!isSameYear ? ' YYYY' : ''}`) + ' - ' + endDate.format('D MMMM YYYY');
        },

        currentPreset () {
            for (const preset of this.presets) {
                const startDate = this.$dayjs(this.currentValue[0]).startOf(preset).format('YYYY-MM-DD');
                const endDate = this.$dayjs(this.currentValue[0]).endOf(preset).format('YYYY-MM-DD');

                if (this.currentValue[0] === startDate && this.currentValue[1] === endDate) {
                    return preset;
                }
            }

            return 'custom';
        },

        pickerOptions () {
            return {
                disabledDate: date => {
                    const formattedDate = this.$dayjs(date).format('YYYY-MM-DD');

                    return (
                        (this.minDate && this.$dayjs(this.minDate).format('YYYY-MM-DD') > formattedDate) ||
                        (this.maxDate && this.$dayjs(this.maxDate).format('YYYY-MM-DD') < formattedDate)
                    );
                },
            };
        },

        prevDisabled () {
            const formattedDate = this.$dayjs(this.currentValue[0]).format('YYYY-MM-DD');

            return this.minDate && this.$dayjs(this.minDate).format('YYYY-MM-DD') >= formattedDate;
        },

        nextDisabled () {
            const formattedDate = this.$dayjs(this.currentValue[1]).format('YYYY-MM-DD');

            return this.maxDate && this.$dayjs(this.maxDate).format('YYYY-MM-DD') <= formattedDate;
        },

        visiblePresets () {
            return this.presets.filter(preset => preset !== 'custom');
        },
    },

    methods: {
        applyPreset (preset) {
            if (preset === 'custom') {
                this.$refs.datePicker.focus();
                return;
            }

            const startDate = this.$dayjs().startOf(preset).format('YYYY-MM-DD');
            const endDate = this.$dayjs().endOf(preset).format('YYYY-MM-DD');

            if (this.currentValue[0] !== startDate || this.currentValue[1] !== endDate) {
                this.currentValue = [startDate, endDate];
            }

            this.$refs.popover.doClose();
        },

        normalizeValue (value, unit) {
            this.currentValue = [
                this.$dayjs(Array.isArray(value) ? value[0] : value).startOf(unit).format('YYYY-MM-DD'),
                this.$dayjs(Array.isArray(value) ? value[1] : value).endOf(unit).format('YYYY-MM-DD'),
            ];
        },

        handlePrevButtonClick () {
            const preset = this.currentPreset;
            if (!preset) {
                return;
            }

            const startDate = this.$dayjs(this.currentValue[0]);
            const endDate = this.$dayjs(this.currentValue[1]);
            if (preset === 'custom') {
                if (this.isFullMonthRange) {
                    const diffInMonths = endDate.diff(startDate, 'month') + 1;
                    this.currentValue = [
                        startDate.subtract(diffInMonths, 'month').format('YYYY-MM-DD'),
                        endDate.subtract(diffInMonths, 'month').endOf('month').format('YYYY-MM-DD'),
                    ];
                } else {
                    const diffInDays = endDate.diff(startDate, 'day') + 1;
                    this.currentValue = [
                        startDate.subtract(diffInDays, 'day').format('YYYY-MM-DD'),
                        endDate.subtract(diffInDays, 'day').format('YYYY-MM-DD'),
                    ];
                }
            } else {
                this.currentValue = [
                    startDate
                        .subtract(1, preset)
                        .startOf(preset)
                        .format('YYYY-MM-DD'),
                    startDate
                        .subtract(1, preset)
                        .endOf(preset)
                        .format('YYYY-MM-DD'),
                ];
            }
        },

        handleNextButtonClick () {
            const preset = this.currentPreset;
            if (!preset) {
                return;
            }

            const startDate = this.$dayjs(this.currentValue[0]);
            const endDate = this.$dayjs(this.currentValue[1]);
            if (preset === 'custom') {
                if (this.isFullMonthRange) {
                    const diffInMonths = endDate.diff(startDate, 'month') + 1;
                    this.currentValue = [
                        startDate.add(diffInMonths, 'month').format('YYYY-MM-DD'),
                        endDate.add(diffInMonths, 'month').endOf('month').format('YYYY-MM-DD'),
                    ];
                } else {
                    const diffInDays = endDate.diff(startDate, 'day') + 1;
                    this.currentValue = [
                        startDate.add(diffInDays, 'day').format('YYYY-MM-DD'),
                        endDate.add(diffInDays, 'day').format('YYYY-MM-DD'),
                    ];
                }
            } else {
                this.currentValue = [
                    startDate
                        .add(1, preset)
                        .startOf(preset)
                        .format('YYYY-MM-DD'),
                    startDate
                        .add(1, preset)
                        .endOf(preset)
                        .format('YYYY-MM-DD'),
                ];
            }
        },

        handleDatePickerChange (value) {
            if (this.pickerType === 'monthrange') {
                this.normalizeValue(value, 'month');
            }
            this.$refs.popover.doClose();
        },

        handleSinglePresetDatePickerInput (value) {
            this.normalizeValue(value, this.presets[0]);
        },
    },
};
</script>
