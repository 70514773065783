<template>
    <a
        v-if="hasAccess && !preventHistoryChange"
        :href="resolvedRoute.href"
        class="el-link"
        :class="`el-link--${type}`"
        @click.prevent.stop="handleClick"
    >
        <slot />
    </a>
    <span
        v-else-if="hasAccess && preventHistoryChange"
        class="el-link"
        :class="`el-link--${type}`"
        @click.prevent.stop="handleClick"
    >
        <slot />
    </span>
    <span
        v-else
        class="el-link"
        style="cursor: text"
    >
        <slot />
    </span>
</template>

<script>
export default {
    name: 'UiDrawerLink',

    props: {
        name: {
            type: String,
            required: true,
        },
        params: {
            type: Object,
            required: true,
        },
        query: {
            type: Object,
            default: () => ({}),
        },
        checkPermissions: {
            type: Boolean,
            default: true,
        },
        type: {
            type: String,
            default: 'default',
        },
        preventHistoryChange: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        location () {
            return {
                name: this.name,
                params: this.params,
                query: this.query,
            };
        },

        resolvedRoute () {
            return this.$router.resolve(this.location);
        },

        hasAccess () {
            if (!this.checkPermissions) {
                return true;
            }

            const attributes = this.resolvedRoute?.route?.meta?.perms ?? [];

            return !attributes.length || this.$can(attributes);
        },
    },

    methods: {
        handleClick () {
            this.$drawer.push(
                this.location,
                { preventHistoryChange: this.preventHistoryChange },
            );
        },
    },
};
</script>
