import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';
import AuthAPI from '@/api/auth';
import router from '@/router';
import { detect as detectBitrix24 } from '@/utils/bitrix24';
import { impersonate } from '@/api/user';

const saveTokenInCookies = (token, name = 'token') => {
    const { exp: expires } = jwtDecode(token);

    Cookies.set(name, token, {
        secure: true,
        sameSite: detectBitrix24() ? 'none' : 'strict', // TODO
        expires: new Date(expires * 1000),
    });
};

export default {
    namespaced: true,
    state: {
        isLoading: false,
        error: null,
        token: Cookies.get('token') ?? null,
        originalToken: Cookies.get('originalToken') ?? Cookies.get('token') ?? null,
    },
    getters: {
        isLoading (state) {
            return state.isLoading;
        },
        hasError (state) {
            return state.error !== null;
        },
        error (state) {
            return state.error;
        },
        isAuthenticated (state) {
            return state.token !== null;
        },
        token (state) {
            return state.token;
        },
        userId (state) {
            if (!state.token) {
                return null;
            }

            return jwtDecode(state.token)?.id ?? null;
        },
        isSuperAdmin (state) {
            if (!state.originalToken) {
                return false;
            }

            return (jwtDecode(state.originalToken)?.roles ?? []).includes('ROLE_ADMIN');
        },
        isImpersonated (state) {
            if (!state.token || !state.originalToken) {
                return false;
            }

            const token = jwtDecode(state.token);
            const originalToken = jwtDecode(state.originalToken);

            return token.id !== originalToken.id;
        },
    },
    mutations: {
        AUTHENTICATING (state) {
            state.isLoading = true;
            state.error = null;
            state.token = null;
        },
        AUTHENTICATING_SUCCESS (state, { token }) {
            state.isLoading = false;
            state.error = null;
            state.token = token;

            const { exp: expires } = jwtDecode(token);
            if (expires) {
                saveTokenInCookies(token);
            }
        },
        AUTHENTICATING_ERROR (state, error) {
            state.isLoading = false;
            state.error = error;
            state.token = null;
        },
        INVALIDATE_TOKEN (state) {
            state.error = null;
            state.token = null;
            state.originalToken = null;
        },
    },
    actions: {
        async login ({ commit }, { email, password }) {
            commit('AUTHENTICATING');
            try {
                const data = await AuthAPI.login(email, password);
                commit('AUTHENTICATING_SUCCESS', { token: data.token });
                return data;
            } catch (error) {
                console.error(error);
                commit('AUTHENTICATING_ERROR', error);
                return null;
            }
        },

        async refreshToken ({ commit }) {
            try {
                const data = await AuthAPI.refreshToken();
                commit('AUTHENTICATING_SUCCESS', { token: data.token });
                return data;
            } catch (error) {
                return null;
            }
        },

        async logout ({ dispatch }) {
            try {
                await AuthAPI.logout();
                await dispatch('invalidateToken');
                await dispatch('resetMe', null, { root: true });
                await dispatch('security/resetPermissions', null, { root: true });
                await router.push({ name: 'Login' });
            } catch (e) {
                console.error(e);
            }
        },

        invalidateToken ({ commit }) {
            Cookies.remove('token');
            Cookies.remove('originalToken');
            commit('INVALIDATE_TOKEN');
        },

        async impersonate ({ commit, dispatch }, { userId }) {
            try {
                const data = await impersonate({ userId });
                const originalToken = Cookies.get('token');
                saveTokenInCookies(originalToken, 'originalToken');
                commit('AUTHENTICATING_SUCCESS', { token: data.token });
                window.location.href = '/';
            } catch (e) {
                console.error(e);
            }
        },

        async stopImpersonation ({ commit, dispatch }) {
            const originalToken = Cookies.get('originalToken');
            Cookies.remove('originalToken');
            commit('AUTHENTICATING_SUCCESS', { token: originalToken });
            window.location.href = '/';
        },
    },
};
