import { groupBy, map, orderBy, sumBy } from 'lodash';
import { Module } from 'vuex';
import { Group, GroupSummary, Notification } from '@/types/Notification';
import { getNotificationGroups } from '@/api/notificationGroup';
import { getLastNotifications, getNotifications, updateNotification } from '@/api/notification';
import { PagedCollection } from '@/types/hydra';
import { useFormat } from '@/composable/useFormat';
import { NotificationGroups } from '@/enums';

interface State {
    groups: Group[],
    groupsError: Error | null,
    groupSummary: GroupSummary[],
    groupSummaryError: Error | null,
    notifications: Notification[],
    notificationsError: Error | null,
    isLoading: boolean,
    isNotificationsLoading: boolean,
    currentGroup: NotificationGroups | null
}

const module: Module<State, any> = {
    namespaced: true,
    state: {
        groups: [],
        groupsError: null,
        groupSummary: [],
        groupSummaryError: null,
        notifications: [],
        notificationsError: null,
        isLoading: false,
        isNotificationsLoading: false,
        currentGroup: null
    },
    getters: {
        lastMessages: state => orderBy(
            state.groups.map(i => ({
                id: i.id,
                group: i,
                summary: state.groupSummary.find(notification => notification.group === i.id) ?? null
            })),
            [({ summary }) => summary?.lastMessageDate ?? '', 'group.name'],
            ['desc', 'asc']
        ),

        totalUnreadCount: state => sumBy(state.groupSummary, 'unreadCount'),

        notificationsSorted: state => orderBy(state.notifications, 'createdAt', ['asc']),

        groupedNotifications: (state, getters) => {
            const { formatDate } = useFormat();

            return orderBy(
                map(
                    groupBy(getters.notificationsSorted as Notification[], i => formatDate(i.createdAt, 'YYYY-MM-DD')),
                    (value, key) => ({
                        date: key,
                        messages: value
                    })),
                'date',
                ['desc']
            );
        }
    },
    mutations: {
        FETCH_GROUPS_START (state) {
            state.isLoading = true;
            state.groupsError = null;
        },

        FETCH_GROUPS_SUCCESS (state, data: Group[]) {
            state.isLoading = false;
            state.groups = data;
        },

        FETCH_GROUPS_ERROR (state, error) {
            state.isLoading = false;
            state.groups = [];
            state.groupsError = error;
        },

        FETCH_SUMMARY_START (state) {
            state.isLoading = true;
            state.groupSummaryError = null;
        },

        FETCH_SUMMARY_SUCCESS (state, data: GroupSummary[]) {
            state.isLoading = false;
            state.groupSummary = data;
        },

        FETCH_SUMMARY_ERROR (state, error) {
            state.isLoading = false;
            state.groupSummary = [];
            state.groupSummaryError = error;
        },

        FETCH_NOTIFICATIONS_START (state) {
            state.isNotificationsLoading = true;
            state.notificationsError = null;
        },

        FETCH_NOTIFICATIONS_SUCCESS (state, { data, loadMore }: { data: PagedCollection<Notification>, loadMore: boolean }) {
            state.isNotificationsLoading = false;
            if (loadMore) {
                state.notifications.push(...(data['hydra:member'] ?? []));
            } else {
                state.notifications = data['hydra:member'] ?? [];
            }
        },

        FETCH_NOTIFICATIONS_ERROR (state, error) {
            state.isNotificationsLoading = false;
            state.notificationsError = error;
        },

        RECEIVE_MESSAGE (state, data) {
            if (state.currentGroup === data.notificationGroup) {
                state.notifications.push(data);
            }

            const groupSummary = state.groupSummary
                .find(i => i.group === data.notificationGroup);
            if (groupSummary) {
                groupSummary.lastMessageDate = data.createdAt;
                groupSummary.lastMessageText = data.message;
                groupSummary.unreadCount++;
            } else {
                state.groupSummary.push({
                    unreadCount: 1,
                    group: data.notificationGroup,
                    lastMessageText: data.message,
                    lastMessageDate: data.createdAt
                });
            }
        },

        SET_ACTIVE_GROUP (state, group: NotificationGroups | null) {
            state.notifications = [];
            state.currentGroup = group;
        },

        UPDATE_NOTIFICATION_SUCCESS (state, { data }: { data: Notification }) {
            const notification = state.notifications.find(i => i.id === data.id);
            if (notification) {
                notification.readAt = data.readAt;
            }

            const groupSummary = state.groupSummary
                .find(i => i.group === data.notificationGroup);

            if (groupSummary) {
                groupSummary.unreadCount--;
            }
        }
    },
    actions: {
        async fetchGroups ({ commit }) {
            commit('FETCH_GROUPS_START');
            try {
                const response = await getNotificationGroups();
                commit('FETCH_GROUPS_SUCCESS', response.groups);
                return response.groups;
            } catch (e) {
                console.error(e);
                commit('FETCH_GROUPS_ERROR', e);
                return null;
            }
        },

        async fetchSummary ({ commit }) {
            commit('FETCH_SUMMARY_START');
            try {
                const response = await getLastNotifications();
                commit('FETCH_SUMMARY_SUCCESS', response.groups);
                return response.groups;
            } catch (e) {
                console.error(e);
                commit('FETCH_SUMMARY_ERROR', e);
                return null;
            }
        },

        async fetchNotifications ({ commit }, { loadMore = false, params }: { loadMore: boolean, params: Parameters<typeof getNotifications>[0]}) {
            commit('FETCH_NOTIFICATIONS_START');
            try {
                const response = await getNotifications(params);
                commit('FETCH_NOTIFICATIONS_SUCCESS', { data: response, loadMore });
                return response;
            } catch (e) {
                console.error(e);
                commit('FETCH_NOTIFICATIONS_ERROR', e);
                return null;
            }
        },

        receiveMessage ({ commit }, { data }) {
            commit('RECEIVE_MESSAGE', data);
        },

        setActiveGroup ({ commit }, group: NotificationGroups | null) {
            commit('SET_ACTIVE_GROUP', group);
        },

        async updateMessage ({ commit }, { id, data }) {
            try {
                const response = await updateNotification(id, data);
                commit('UPDATE_NOTIFICATION_SUCCESS', { data: response });
                return response;
            } catch (e) {
                console.error(e);
                return null;
            }
        }
    }
};

export default module;
