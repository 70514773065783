import request from '@/utils/request';

const baseUri = '/staff/departments';

export default {
    find (id) {
        return request.get(`${baseUri}/${id}`);
    },

    findAll (params) {
        return request.get(baseUri, { params });
    },

    create (payload = {}) {
        return request.post(baseUri, payload);
    },

    update (id, payload) {
        return request.patch(`${baseUri}/${id}`, payload);
    },

    delete (id) {
        return request.delete(`${baseUri}/${id}`);
    },

    deactivate (id) {
        return request.post(`${baseUri}/${id}/deactivate`);
    },

    changeLeader (id, payload) {
        return request.put(`${baseUri}/${id}/head`, payload);
    },
};
