import request from '@/utils/request';
import { PagedCollection } from '@/types/hydra';
import { TransactionVerification, TransactionVerificationDiff } from '@/types/MoneyTransaction';

const baseUri = '/transaction_verifications';

export const getTransactionVerifications = (params: object): Promise<PagedCollection<TransactionVerification>> =>
    request({
        url: baseUri,
        method: 'GET',
        params
    });

export const getTransactionVerification = (id: string): Promise<TransactionVerification> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET'
    });

export const createTransactionVerification = (data: Record<string, any>): Promise<TransactionVerification> => {
    const formData = new FormData();

    Object.keys(data).forEach(key => {
        formData.append(key, data[key]);
    });

    return request({
        url: baseUri,
        method: 'POST',
        data: formData,
    });
};

export const deleteTransactionVerification = (id: string): Promise<void> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'DELETE'
    });

export const getTransactionVerificationDiff = (id: string, params?: object): Promise<PagedCollection<TransactionVerificationDiff>> =>
    request({
        url: `${baseUri}/${id}/diff`,
        method: 'GET',
        params,
    });

export const rebuildTransactionVerification = (id: string, data: object) =>
    request({
        url: `${baseUri}/${id}/rebuild`,
        method: 'POST',
        data,
    });

export const addTransactionsFromVerification = (id: string, data: object) =>
    request({
        url: `${baseUri}/${id}/add_transactions`,
        method: 'POST',
        data,
    });
