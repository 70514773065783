import AccountAPI from '@/api/account';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    only: ['FETCH_LIST', 'FETCH_SINGLE', 'RESET'],
    doFetchList (params) {
        return AccountAPI.findAll(params);
    },
    doFetchSingle (id) {
        return AccountAPI.find(id);
    },
});
