import type { Module } from 'vuex';
import Vue from 'vue';
import { pickBy } from 'lodash';
import { getPermissions } from '@/api/user';
import { BusinessMemberTypes } from '@/enums';

interface LegacyBusinessPermissions {
    [key: string]: BusinessPermission;
}

interface BusinessPermission {
    [key: string]: {
        hasAccess: boolean;
        extra?: any;
    };
}

interface BusinessMembership {
    businessId: string;
    businessSlug: string;
    rights: BusinessPermission,
    role: string;
}

interface BusinessGroupMembership {
    businessGroupId: string;
    isOwner: boolean;
    permissions: string[];
}

interface State {
    permissions: {
        [key: string]: LegacyBusinessPermissions;
    },
    businessMemberships: BusinessMembership[]
    businessGroupMemberships: BusinessGroupMembership[],
    roles: {
        [key: string]: string;
    };
    loading: boolean;
}

export default {
    namespaced: true,
    state: {
        permissions: {},
        businessMemberships: [],
        businessGroupMemberships: [],
        roles: {},
        loading: false,
    },
    getters: {
        permissions: (state: State) => (businessId?: string): any => {
            if (!businessId) {
                return state.permissions ?? null;
            } else {
                return state.permissions[businessId] ?? null;
            }
        },
        role: (state: State) => (businessId: string) => state.roles[businessId] || null,
        hasAccess: (state: State) => (attribute: string, businessId: string) => {
            return Boolean(
                state.roles[businessId] === BusinessMemberTypes.OWNER ||
                state.permissions[businessId]?.[attribute]?.hasAccess ||
                state.permissions[businessId]?.ALL?.hasAccess,
            );
        },
        hasPermissionInBusinessGroup: (state: State) => (permission: string, businessGroupId: string) => {
            const membership = state.businessGroupMemberships.find(ms => ms.businessGroupId === businessGroupId);
            return membership?.isOwner || (membership?.permissions ?? []).includes(permission);
        },
        isOwnerOfBusinessGroup: (state: State) => (businessGroupId: string) => {
            const membership = state.businessGroupMemberships.find(ms => ms.businessGroupId === businessGroupId);
            return membership?.isOwner ?? false;
        },
        isLoading: (state: State) => () => state.loading ?? false,
        filterCodes: (state: State) => (attribute, businessId) => state.permissions[businessId]?.[attribute]?.extra?.map(i => i.filterCode) ?? [],
        extra: (state: State) => (attribute, businessId) => state.permissions[businessId]?.[attribute]?.extra ?? [],
    },
    mutations: {
        FETCH_PERMISSIONS_START (state: State) {
            state.loading = true;
        },
        FETCH_PERMISSIONS_SUCCESS (state: State, data: any) {
            state.businessMemberships = data.businesses;
            state.businessGroupMemberships = data.businessGroups;
            data.businesses.forEach(business => {
                if (business.rights) {
                    const permissions = {};
                    for (const [key, value] of Object.entries(business.rights)) {
                        // noinspection TypeScriptValidateTypes
                        permissions[key] = pickBy(value, (v, k) => !k.startsWith('@'));
                    }
                    Vue.set(state.permissions, business.businessId, permissions);
                } else {
                    Vue.set(state.permissions, business.businessId, null);
                }
                Vue.set(state.roles, business.businessId, business.role ?? '');
            });
            state.loading = false;
        },
        RESET_PERMISSIONS (state: State) {
            state.permissions = {};
            state.businessMemberships = [];
            state.businessGroupMemberships = [];
            state.roles = {};
            state.loading = false;
        },
    },
    actions: {
        async fetchPermissions ({ commit }, { userId }) {
            commit('FETCH_PERMISSIONS_START');
            try {
                const data = await getPermissions(userId);
                commit('FETCH_PERMISSIONS_SUCCESS', data);
                return data;
            } catch (e) {
                commit('RESET_PERMISSIONS');
                return null;
            }
        },

        resetPermissions ({ commit }) {
            commit('RESET_PERMISSIONS');
        },
    },
} as Module<State, any>;
