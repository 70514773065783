import request from '@/utils/request';

const baseUri = '/accounts';

export default {
    find (id) {
        return request.get(`${baseUri}/${id}`);
    },

    findAll (params, ignoreThirdPerson = true) {
        if (ignoreThirdPerson) {
            params = {
                'businessRequisite.isThirdPerson': false,
                ...params,
            };
        }

        return request.get(baseUri, { params });
    },

    create (payload) {
        return request.post(baseUri, payload);
    },

    update (id, payload) {
        return request.patch(`${baseUri}/${id}`, payload);
    },

    delete (id) {
        return request.delete(`${baseUri}/${id}`);
    },
};
