import request from '@/utils/request';

const baseUri = '/contractor_requisites';

export const getRequisites = (params, withoutThirdPerson = true) => {
    const finalParams = withoutThirdPerson
        ? { isThirdPerson: false, ...params }
        : { ...params } ;

    return request({
        url: baseUri,
        method: 'GET',
        params: finalParams,
    });
};

export const getRequisite = (id, params) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET',
        params,
    });

export const createRequisite = (data) =>
    request({
        url: baseUri,
        method: 'POST',
        data,
    });

export const updateRequisite = (id, data) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'PATCH',
        data,
    });

export const deleteRequisite = (id) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'DELETE',
    });

export const moveRequisiteToContractor = (id, data, params) =>
    request({
        url: `${baseUri}/${id}/move_to_contractor`,
        method: 'POST',
        data,
        params,
    });

export const moveRequisiteToBusinessRequisite = (id, params) =>
    request({
        url: `${baseUri}/${id}/move_to_business_requisite`,
        method: 'POST',
        params,
    });

/**
 * @deprecated Используйте createAccount из api/contractor/account.js
 */
export const createRequisiteAccount = (requisiteId, data) =>
    request({
        url: '/contractor_bank_accounts',
        method: 'POST',
        data: {
            ...data,
            contractorRequisite: requisiteId,
        },
    });

/**
 * @deprecated Используйте updateAccount из api/contractor/account.js
 */
export const updateRequisiteAccount = (requisiteId, accountId, data) =>
    request({
        url: `/contractor_bank_accounts/${accountId}`,
        method: 'PATCH',
        data,
    });

/**
 * @deprecated Используйте deleteAccount из api/contractor/account.js
 */
export const deleteRequisiteAccount = (requisiteId, accountId) =>
    request({
        url: `/contractor_bank_accounts/${accountId}`,
        method: 'DELETE',
    });

/**
 * @deprecated Используйте moveToBusinessAccount из api/contractor/account.js
 */
export const moveAccountToBusinessAccount = (id, data, params) =>
    request({
        url: `/contractor_accounts/${id}/move_to_business_account`,
        method: 'POST',
        data,
        params,
    });
